import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueGtag from "vue-gtag";

import { router } from './router/index'
import VueRouter from 'vue-router'
import VueParallaxJs from 'vue-parallax-js'

//import Scrollbar from 'smooth-scrollbar';

import AOS from 'aos';
import 'aos/dist/aos.css'; 
import Aos from 'aos';

let itemsToUse = [
  VueRouter, AOS,   
]

itemsToUse.forEach((item)=>{
  Vue.use(item)
})


const options = {
  minWidth: 1025,   /* minumum window width for parallax to take effect */
}
Vue.use(VueParallaxJs, options)

router().then(router => {
  Vue.use(VueGtag, {
    config: { id: "G-GWPQ8SGQNR" }
  }, router);

  router.afterEach((to) => {
    //const lastRouteName = JSON.parse(localStorage.getItem('LS_ROUTE_KEY'))
    // al refresh non c'e' il route id (rid)
    if (to.params.rid == null && to.name !== 'Home') return
    const myto = {
        "path": to.path,
        "name": to.name,
        "params": to.params
    }
    localStorage.setItem('LS_ROUTE_KEY', JSON.stringify(myto))
  })

  Vue.config.productionTip = false

  new Vue({
    created(){

      Aos.init({
        once: true, 
        animatedClassName: 'aos-animate',
      })
    },
    router,
    store,
    render: (h) => h(App)
  }).$mount("#app")

})