<template>
  <header :class="{sticky : windowScrolled}">
    <nav class="navbar" :class="lang">
      <div>

        <div>
          <router-link :to="gotohome()" class="navbar-brand" @click.native="closeMenu">
            <img src="../assets/logo-dark.svg" alt="logo" class="logo-desktop pdg" />
          </router-link>
          <div class="navbar-toggler">
            <label for="check">
              <span class="hamburger-menu" id="check" @click="toggleMenu">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </label>
          </div>
        </div>

        <div :class="['inner-container', menuOpen ? 'show' : '']">
          <!-- create a list of menu routes -->
          <ul class="navbar-nav">
            
            <!-- for every top level menu route ... -->
            <li v-for="(item, index) of menuItems" :key="'menu' + index"
              :class="['nav-item', index === submenuOpen ? 'active' : '']">
              <!-- if has children is not clickable -->
              <div v-if="item.children && item.children.length > 0" class="nav-item-content"
                @click="openSubmenu(index)">
                <span class="nav-link dropdown">{{ item.label }}</span>
              </div>

              <!-- if it's a top level route menu, it is a link -->
              <div v-else>
                <router-link class="nav-link" :to="/*lang=='it' ? ('/it' + item.url) : */item.url" @click.native="closeMenu">
                  {{ item.label }}
                </router-link>
              </div>

              <!-- if it has children, create a list of sub links -->
              <div v-if="item.children && item.children.length > 0" class="submenu" v-show="submenuVisible" >
                <div class="description">
                  <h2>{{ item.label }}</h2>
                  <!-- CABLAGGIO DESCRIZIONI HEADER -->
                  <!--<p v-html="menuData.find(m => m.attributes.title == item.label).attributes.description"></p>-->
                  <p v-if="descriptions[lang].find(d => d.key == item.label)" 
                    :class="{ 'polomenu': (
                      item.label == 'Il Polo' 
                      || item.label == 'Area Stampa' 
                      || item.label == 'Press Area'
                    )}"
                    v-html="descriptions[lang].find(d => d.key == item.label).text"></p>
                </div>
                <div class="list">
                  <h2>{{ item.label }}</h2>
                  <ul :class="['', submenuOpen === index ? 'active': '']">
                    <li v-for="(child, index) of item.children" :key="index">
                      <!-- create a clickable link for every child -->
                      <div>
                        <router-link :class="['nav-link-custom', child.label ]" :to="/*lang=='it' ? ('/it' + child.url) : */child.url" @click.native="closeMenu" >
                          {{ child.label }}
                        </router-link>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="news" v-if="newsData.length > 0">
                  <h2>news</h2>
                  <router-link :to="(lang==defaultLang ? '' : ('/'+lang)) + newsLink(n)" v-for="(n, index) in newsData" :key="'news-header-'+index"
                    class="news-item">
                    <!--<p v-html="n.attributes.field_news_description.processed"></p>-->
                    <p>{{ n.attributes.title }}</p>
                    <p class="date">
                      {{ convertDate(n.attributes.field_news_date) }}
                    </p>
                  </router-link>
                  <a class="link" @click="gotonews()">{{ lang!=defaultLang ? 'All the News' : 'Tutte le News' }}</a>
                </div>
              </div>
            </li>

            <li class="nav-item">
              <div class="nav-item-content">
                <span class="nav-link">
                  <div class="lang">
                    <a 
                      v-for="(ll, index) in this.$store.state.languages" 
                      :key="'lang'+index"
                      :class="{'nav-link': true, 'selected': lang==ll}" 
                      @click="changelang(ll)">{{ ll }}
                    </a>
                  </div>
                </span>
              </div>
            </li>

          </ul>
        </div>

      </div>
    </nav>
  </header>
</template>

<script>
  import {
    fetchNodes, getTranslation
  } from '../libs/drupalClient'
  import {
    detailsLink,
    reWriteDate
  } from '../libs/utils'
  export default {
    name: 'the-header',
    data() {
      return {
        windowScrolled: false,
        menuOpen: false,
        submenuOpen: null,
        submenuVisible:true,
        newsData: [],
        menuData: [],
        descriptions: { 
          en: [{
              key: 'Il Polo',
              text: "Our wish is to deliver better products to future generations, created in healthier environments, respectful of nature and human labour."
            },
            {
              key: 'Brands',
              text: "Products are almost always combinations of the tangible and the intangible.<br>~ Theodore Levitt"
            },
            {
              key: 'Press Area',
              text: 
                '<span style="color: #D2BC75"> To contact Press Office use: </span> <br>' +
                '<a href="mailto:ufficiostampa@polodelgusto.com" style="text-decoration:none">ufficiostampa@polodelgusto.com</a>'
            }],
          it: [{
              key: 'Il Polo',
              text: "Desideriamo consegnare alle generazioni future prodotti più buoni, realizzati in ambienti più sani, rispettosi della natura e del lavoro dell'uomo."
            },
            {
              key: 'I Marchi',
              text: "I prodotti sono quasi sempre combinazioni di qualità materiali e immateriali."
            },
            {
              key: 'Area Stampa',
              text: 
                '<span style="color: #D2BC75"> Per contattare l\'Ufficio Stampa: </span> <br>' +
                '<a href="mailto:ufficiostampa@polodelgusto.com" style="text-decoration:none">ufficiostampa@polodelgusto.com</a>'
            }]
        },
        menuItems: []
      };
    },
    computed: {
      lang() {
        return this.$store.state.currentLang
      },
      defaultLang() {
        return this.$store.state.defaultLang
      }
    },
    methods: {
      gotonews() {
        this.$router.push({
          path: (this.lang!=this.defaultLang ? ('/' + this.lang) : '') + '/news'
        })
      },
      gotohome() {
        return this.lang!=this.defaultLang ? ('/' + this.lang) : '/'
      },
      changelang(lang) {
        localStorage.setItem('LS_LANG_KEY', lang)
        this.$store.commit('SET_CURRENT_LANG', lang)
        //console.log(localStorage.getItem('LS_LANG_KEY'))
      },
      convertDate(d) {
        return reWriteDate(d)
      },
      newsLink(n) {
        return detailsLink('news', n)
      },
      //navigation menu structure (routing) based on drupal jsonapi
      async fetchMenuItems() {
        var menu = []
        var items = []
        var ch = []

        await fetch(`${process.env.VUE_APP_ENDPOINT}${this.lang!=this.defaultLang ? ('/'+this.lang) : ''}/jsonapi/menu_items/main`)
          .then(res => res.json())
          .then(json => {
            this.menuData = json.data
            //console.log(this.menuData)
            items = json.data
          })

        //for each fetched route
        items.forEach(item => {
          ch = []

          //for each menu item with no parent
          if (item.attributes.parent === "" || item.attributes.parent === undefined) {
            //if the number of its children is 0
            if (items.filter(it => it.attributes.parent === item.id).length == 0) {
              //push it into the menu as a top-level route
              menu.push({
                label: item.attributes.title,
                url: item.attributes.url
              })

            } else {
              //else search for its children
              items.filter(it => it.attributes.parent === item.id)
                //and create an array of routes
                .forEach(child => ch.push({
                  label: child.attributes.title,
                  url: child.attributes.url
                }))
              //then push them into the menu as a sub-level route (for that corresponding top-level menu)
              menu.push({
                label: item.attributes.title,
                children: ch
              })
            }
          }
        })

        //create the menu
        //console.log('menu items', menu)
        this.menuItems = menu

      },
      toggleMenu() {
        this.menuOpen ? this.closeMenu() : this.openMenu();
        this.openSubmenu(null);
      },
      openMenu() {
        document.querySelector("html").classList.add("menu-open");
        document.body.classList.add("menu-open");
        this.menuOpen = true;
      },
      hideSubmenu(){
        // hide temporary sub menu after click
        this.submenuVisible=false
        setTimeout(()=>{
          this.submenuVisible=true
        },100)
      },
      closeMenu() {
        document.querySelector("html").classList.remove("menu-open");
        document.body.classList.remove("menu-open");
        this.openSubmenu(null);
        this.menuOpen = false;

        this.hideSubmenu()

      },
      //just one submenu can stay open at a time
      openSubmenu(index) {
        if (this.submenuOpen === index) {
          this.submenuOpen = null;
        } else {
          this.submenuOpen = index;
        }
      },
    },
    //as page is created, fetch for backend routing
    created() {
      this.fetchMenuItems();
      window.addEventListener('scroll', this.handleScroll);
    },
    watch: {
      lang() {
        this.fetchMenuItems()
        //console.log(this.$route)

        
        getTranslation(this.$route.name, this.lang).then(res => {
          //console.log(res)
          localStorage.setItem('LS_ROUTE_KEY', res)  
        })
      },
      menuItems() {
        fetchNodes('news', {}, this.lang)
          .then(res => {
            this.newsData = res.sort((a, b) => {
                return a.attributes.field_news_date > b.attributes.field_news_date ? -1 : 1
              })
              .slice(0, 2)
          })
      }
    }
  };
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";


/* header .navbar-nav li:nth-child(5) .description p{
  background-color: red !important;
  display: none;
} */


  /*HIDE NEWS IN ENG */
  .navbar.en .submenu .news,
  .navbar.en .nav-link-custom.News{
    display: none !important;
  }


  .hamburger-menu {
    width: 40px;
    cursor: pointer;
    height: 25px;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    position: relative;

    span {
      height: 2px;
      background-color: rgb(139, 139, 139);
      position: relative;
      width: 100%;
    }
  }

  .menu-open .hamburger-menu {
    span {
      top: 50%;
      transform: translateY(-50%);
      position: absolute;

      &:nth-child(2) {
        display: none;
      }

      &:nth-child(1) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }

  .inner-container .nav-item .submenu .news .news-item:hover {
    p:not(.date) {
      transition: 0.2s ease;
      color: $mainColor;
    }
  }

  .inner-container .nav-item .submenu .news .link:hover {
    transition: 0.2s ease;
    color: lighten($mainColor, 10%);
  }

  header {
    background-color: #fff;
    box-shadow: 0px 0px 69px 0px #00000017;
    z-index: 9999;
  }

  .nav-link {
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    padding: 2rem 1rem;
    font-size: 1rem;
  }

  .nav-item {
    cursor: pointer;
  }

  .nav-item:hover div:not(.lang) .nav-link {
    cursor: pointer;
    color: $mainColor;
    transition: 0.2s ease;
  }

  .inner-container .nav-item .submenu {
    padding: 0;
    box-shadow: 0px 11px 10px 0px #0000000d;
    top: -253px;
    display: none;

    li {
      width: auto;
      margin: 0em 0;
    }

    a {
      border-bottom: 1px solid #d3d3d3;
      width: auto;
      padding: 12px 0rem;
      margin: 0em 0;
      text-decoration: none;
      display: block;
    }

    .description,
    .list,
    .news {
      padding: 2rem 4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .description {
      width: 50%;
      background:#E8E6DE url("../assets/filigrana-menu.png");
      background-size: cover;
      background-position: center;
      h2 {
        font-size: 2.125rem;
      }

      p {
        position: relative;
        padding-top: 3rem;

        &:not(.polomenu)::before {
          content: url("../assets/icon-quote.png");
          position: absolute;
          top: 20px;
        }
      }
    }

    .news,
    .list {
      width: 25%;
    }

    li {
      list-style: none;
    }

    .news {
      position: relative;

      &:before {
        content: "";
        height: calc(100% - 4rem);
        background: #aaaaaa3b;
        position: absolute;
        width: 1px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        content: "";
        left: 0;
      }

      h2 {
        color: #D2BC75;
        font-family: "Poppins";
        font-size: 1rem;
        text-transform: uppercase;
        margin-bottom: 8px;
      }

      .news-item {
        border-bottom: 1px solid #d3d3d3;
        padding: 6px 0px;
      }

      p {
        color: #333333;
        font-size: 0.9375rem;
        line-height: 1.5rem;
        margin-bottom: 5px;
        font-weight: 500;
      }

      .date {
        color: #7C7C7C;
        font-size: 0.8125rem;
      }

      a {
        color: #D2BC75;
        text-decoration: none;
        padding: 1rem 0rem;
        font-size: 0.9375rem;
        border-bottom: 0px solid #d3d3d3;
      }
    }

  }

  .inner-container .nav-item:hover .submenu {
    background-color: #f0eae6;
    position: fixed;
    display: flex;
    left: 0;
    width: 100%;
    top: 88px;
  }

  .nav-link {
    padding: 2rem 1rem;
  }

  @media (max-width:1500px) {
    .inner-container .nav-item .submenu {

      .list,
      .news {
        width: 30%;
        padding: 2rem 3rem;
      }

      .description {
        width: 40%;
        padding: 2rem 3rem;
      }
    }
  }

  @media (max-width:1200px) {
    .inner-container .nav-item .submenu {
      .news {
        display: none;
      }

      .list,
      .description {
        width: 50%;
        padding: 3rem 4rem;
      }
    }
  }

  @media (max-width:992px) {
      .nav-link.dropdown{
    position: relative;
&:after {
      background-image: url("../assets/icon-dropdown.png");
      right: -15px;
      top: 50%;
      position: absolute;
      content: "";
      width: 13px;
      height: 8px;
      display: block;
      transform: translateY(-50%);
    }
  }
    header .navbar {
      height: 70px;
    }

    .nav-item:hover .nav-link {
      color: #333333;
    }

    .nav-link {
      padding: 1rem 1rem;
    }

    .nav-item>div:first-child:not(.nav-item-content) {
      top: 0;
    }

    header .navbar>div .inner-container {
      top: 70px;
    }


    .inner-container .nav-item .submenu li:last-child a {
      border-bottom: 0px;

    }

    header .navbar>div .inner-container {
      .nav-item {
        .submenu {

          position: static;
          background: #ebe7e4;
          box-shadow: 0px 0px 0px 0px #0000000d;


          .description {
            display: none;

          }

          .list {
            padding: 1rem 1rem 1rem 1rem;
            width: calc(100% - 2rem);
          }
        }
      }

      .navbar-nav {
        background-color: #f0eae6;
        box-shadow: 0px 5px 15px 0px #0000001a;
      }
    }

    .list h2 {
      display: none;
    }

    .navbar-toggler {
      width: auto;
    }
  }






  @media (max-width:768px) {
    .navbar-brand img {
      height: 40px;
    }

    .submenu,
    .nav-link,
    header .navbar {
      padding-left: 15px;
      padding-right: 15px;
    }

    header .navbar>div .inner-container .navbar-nav {
      margin: 0 -1rem;
      display: block;
    }
  }

  @media (max-width:576px) {
    .hamburger-menu {
      width: 30px;
      height: 20px;
    }
  }

  .lang {
    &:hover {
      color: unset;
    }
    display: flex;
    flex-direction: row;
    .nav-link {
      padding: 0 10px 0 0;
      position: relative;

      &:not(:first-child) {
        padding: 0 10px;
        &::before { 
          content: '/';
          width: 20px;
          height: 20px;
          left: -4px;
          color: black;
          position: absolute;
          font-weight: 500;
        }
      }

      &.selected {
        font-weight: 700;
        color: $mainColor;
      }
    }
  }
</style>