<template>
    <footer>
        <div class="sitemap">
            <div >
                <router-link class="logo" :to="'/' + (lang==defaultLang ? '' : lang)">
                    <img src="../assets/logo-white.svg" alt="">
                </router-link>
                <div>
                    <div class="footer-column" v-for="(item, index) in this.menuItems" :key="'footer' + index">
                        <div  v-if="item.children!=undefined">
                            <h4>{{ item.label }}</h4>
                            <router-link v-for="(child, index) in item.children" :key="item.label + 'child' + index" :to="child.url">
                                <a> {{ child.label }} </a>
                            </router-link>
                        </div>
                        <div v-else>
                            <router-link :to="item.url" class="nomargin">
                                <h4>{{ item.label }}</h4>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contacts">
            <div >
                <p>{{ this.lang=='it' ?
                    '© 2021 Polo del Gusto S.r.l. | P.IVA 10881980964 - Riva Tommaso Gulli 12, 34123 Trieste – Italia - Tutti I Diritti Riservati' :
                    '© 2021 Polo del Gusto S.r.l. | P.IVA 10881980964 - Riva Tommaso Gulli 12, 34123 Trieste – Italy - All Rights Reserved'
                }}</p>
                <p>
                    <a href="./PdG-Codice-Etico.pdf" download>
                        {{ this.lang=='it' ? 'Codice etico' : 'Ethical code'}}
                    </a>
                    <!--<router-link to="/privacy-policy">
                        Privacy Policy
                    </router-link>-->
                    <router-link :to="cookiePolicyRoute">
                        Cookie Policy
                    </router-link>
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'the-footer',
    data: () => {
        return {
            menuItems: []
        }
    },
    computed: {
        cookiePolicyRoute(){
            if(this.lang==this.defaultLang)
                return '/cookie-policy'
            else
                return '/' + this.lang + '/cookie-policy'
        },
        lang() {
            return this.$store.state.currentLang
        },
        defaultLang() {
            return this.$store.state.defaultLang
        }
    },
    methods: {
        async fetchMenuItems() {
        var menu = []
        var items = []
        var ch = []

        await fetch(`${process.env.VUE_APP_ENDPOINT}${this.lang!=this.defaultLang ? ('/'+this.lang) : ''}/jsonapi/menu_items/main`)
          .then(res => res.json())
          .then(json => {
            this.menuData = json.data
            //console.log(this.menuData)
            items = json.data
          })

        //for each fetched route
        items.forEach(item => {
          ch = []

          //for each menu item with no parent
          if (item.attributes.parent === "" || item.attributes.parent === undefined) {
            //if the number of its children is 0
            if (items.filter(it => it.attributes.parent === item.id).length == 0) {
              //push it into the menu as a top-level route
              menu.push({
                label: item.attributes.title,
                url: item.attributes.url
              })

            } else {
              //else search for its children
              items.filter(it => it.attributes.parent === item.id)
                //and create an array of routes
                .forEach(child => ch.push({
                  label: child.attributes.title,
                  url: child.attributes.url
                }))
              //then push them into the menu as a sub-level route (for that corresponding top-level menu)
              menu.push({
                label: item.attributes.title,
                children: ch
              })
            }
          }
        })

        //create the menu
        //console.log('menu items', menu)
        this.menuItems = menu

      }
    },
    created() {
        this.fetchMenuItems();
    },
    watch: {
        lang() {
            this.fetchMenuItems()
        }
    }
}
</script>

<style scoped lang="scss">
@import "../styles/colors.scss";

/* .footer-column:nth-child(5) {
    display: none;
} */

footer {
    background: #333333 url("../assets/filigrana-footer.png") ;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 3rem 4rem 0rem 4rem;
}

.sitemap {
    padding-bottom: 5rem;
    
    &>div {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        @media (min-width: 992px) {
            flex-direction: row;
            justify-content: space-between;
        }
        &>div {
            display: flex;
            flex-direction: row;
            width: 85%;
        }
        .logo {
            width: 12%;
            img {
                max-width: 270px;
            }
        }
    }

    .footer-column {
        width: 180px;
        margin: 0 0em 0 4em;

        div {
            display: flex;
            flex-direction: column;

            
        }
    }

    h4 {
        color: $mainColor;
        font-weight: 500;
        margin:1rem 0rem ;
        text-transform: uppercase;
        position: relative;
        &:before {
            content: "";
            width: 60px;
            height: 1px;
            position: absolute;
            background-color: $mainColor;
            opacity: 0.5;
            left: -73px;
            top: 12px;
        }
    }
    a {
        color: #f0eae6;
        padding:6px 0px ;
        font-size: 0.9375rem;
        font-weight: 400;
        text-decoration: none;
    }
    a:hover {
        color: $mainColor;
    }

    a.nomargin {
        padding: 0;
        h4:hover {
            color: #fff;
        }
    }
}

.contacts {
    padding: 2rem 0rem;
    border-top: 1px solid rgba(255, 255, 255, 0.212);
    div {
        display: flex;
        justify-content: space-between;
    }
    p {
        color: #7E7E7E;
        font-size: 0.875rem;
        a {
            font-size: 0.875rem;
            color: #7E7E7E;
            text-decoration: none;
            display: inline-block;
            margin: 0 20px;
            &:hover{
                transition: 0.3s ease;
                color: $mainColor;
            }
        }
    }
}

@media (max-width:1500px) {
   .sitemap > div > div {
        width: 75%;
        margin-left: 2rem;
    }
    .sitemap .footer-column {
        width: 25%;
        margin: 0 0em 0 4em;
    }
    .sitemap > div {
        .logo {
            margin-left: 0;
            margin-bottom: 3rem;
            img {
                max-width: 220px;
            }
        }
    }
}

@media (max-width: 1200px) { 
    .sitemap > div .logo {
        width: 16%;
        margin-right: 4rem;
    }
    .contacts div{
        flex-direction: column;
        p{
            margin-bottom: 1rem;
            a {
                margin-right: 2rem;
                margin-left: 0;
            }
        }
    }

}

@media (max-width: 1200px) { 
     .sitemap > div .logo {
        width: 100%;
    }
    .sitemap > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        &>div.logo,
        &>div {
            width: 100%;
            margin-left: 0;
        }
    }
}

@media (max-width: 992px) { 
    .sitemap {
        padding-bottom: 1rem;
    }
    .sitemap > div > div {
        display: flex;
        flex-direction: column;

    }
    .sitemap .footer-column {
        width: auto;
        margin: 0 0em 30px 6em;
    }
    .contacts div {
        display: flex;
        flex-direction: column;
        text-align: center;
        p {
            margin-bottom: 2rem;
            line-height: 1.5rem;
            display: flex;
            flex-direction: column;
            a {
                margin: 0.5rem 0rem;
                display: block;
            }
        }
    }
}

@media (max-width: 992px) { 
    footer {
        padding: 2rem 15px 0rem 15px;
        background-color:#333333 ;
        background-image:  url("../assets/filigrana-footer.png"),url("../assets/filigrana-footer-2.png") ;
        background-position: bottom left,top right;
    }
}



</style>