<template>
  <div id="app">
    <the-header></the-header>
    <cookie-banner></cookie-banner>
    <router-view></router-view>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import CookieBanner from './components/CookieBanner.vue'

export default {
  components: { 
    TheHeader,
    TheFooter,
    CookieBanner
  },
  data: () => {
    return {
    }
  },
  methods: {
    refreshPage () {
      if(localStorage.getItem('LS_LANG_KEY') != null) {
        //console.log(localStorage.getItem('LS_LANG_KEY'))
        //console.log(this.$route)
        //console.log(this.$store.state)
      } else {
        localStorage.setItem('LS_LANG_KEY', this.$store.state.currentLang)
      }
      this.$store.commit('SET_CURRENT_LANG', localStorage.getItem('LS_LANG_KEY'))
		}
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    }
  },
  mounted(){
    this.refreshPage()
  }
}
</script>

<style lang="scss">
@import "common.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    height: 100vh;
} 

body {
  height: 100vh;
}
</style>
