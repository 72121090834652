<template>
    <!--<router-link to="#" class="news-tile">-->
        <div class="news">
            <div class="img" :style="{  backgroundImage: 'url(' + sourceImg + ')' }" @click="goToNews">
            </div>
            <div class="text">
                <p class="data">
                    {{ ndata.attributes ? this.convertDate : 'mese gg, yyyy' }}
                </p>
                <h2 @click="goToNews">
                    {{ ndata.attributes ? ndata.attributes.title : 'titolo singola news' }}
                </h2>
                <p v-if="ndata.attributes && ndata.attributes.field_news_description" v-html="ndata.attributes.field_news_description.summary"> </p>
                <p class="read-more">
                    <a style="cursor: pointer;" @click="goToNews">{{ this.lang=='it' ? 'Leggi di più' : 'Read more'}}</a>
                    <!-- <router-link :to="{ name: 'details/News', params: { nid: '007', prova: msg, title: 'prova-titolo' }}">{{ this.lang=='it' ? 'Leggi di più' : 'Read more'}}</router-link> -->
                </p>
            </div>
        </div>
    <!--</router-link>-->
</template>

<script>
import { reWriteDate, detailsLink } from '../libs/utils'
export default {
    name: 'news-tile',
    props: {
        ndata: Object
    },
    data: () => {
        return {
            
        }
    },
    methods: {
        goToNews() {
            //console.log(event.target)
            this.$router.push(
                //{ name: 'details/News', params: { nid: '007', prova: this.msg, title: 'test' }}
                (this.lang==this.defaultLang ? '' : ('/' + this.lang)) + detailsLink('news', this.ndata)
            )
        }
    },
    computed: {
        msg() {
            //console.log(this.ndata.attributes.drupal_internal__nid )
            return this.ndata.attributes.drupal_internal__nid 
        },
        dettaglio() {
            return (this.lang==this.defaultLang ? '' : ('/' + this.lang)) + detailsLink('news', this.ndata)
        },
        lang() {
            return this.$store.state.currentLang
        },
        defaultLang() {
            return this.$store.state.defaultLang
        },
        convertDate() {
            return reWriteDate(this.ndata.attributes.field_news_date)
        },
        sourceImg() {
            return process.env.VUE_APP_ENDPOINT + this.ndata.field_news_image.attributes.uri.url
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
.news {
    .img {
        height: 260px;
        cursor: pointer;
        background-size: cover;
        background-position: center;
        margin-bottom: 2rem;
    }
    .text {
        padding: 0;
    }
    img:hover, h2:hover {
    cursor: pointer;
    }
    h2:hover {
        color: $mainColor;
    }
}
</style>