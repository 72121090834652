<template>
    <div class="slide-row">
        <div class="slide-column-1">
            <img class="product" :src="imgSource" alt="">
        </div>
        <div class="slide-column-2">
            <div class="content">
                <h2 v-html="prodData.attributes.title"></h2>
                <h3>{{ prodData.attributes.field_catchphrase }}</h3>
                <p :class="['desc',{ 'open': isOpen.includes(index) }]"  v-html="prodData.attributes.field_product_description.processed"></p>
                <a @click="openAccordion(index)">{{accordinText(index)}}</a>
                <img class="logo" v-if="this.prodData.field_product_logo" :src="brandLogo2" width="auto" height="110" alt="">
            </div>
        </div>
        <div class="slide-column-3">
        <div class="num"><span class="current">{{index}}</span><span>{{total}}</span></div></div>
    </div>
</template>

<script>
export default {
    name: 'scheda-prodotto',
    data: () => {
        return {
            isOpen: []
        }
    },
    props: {
        prodData: {},
        brandLogo: String,
        index: Number,
        total: Number
    },
    computed: {
        logoSource() {
            return process.env.VUE_APP_ENDPOINT + this.prodData.field_product_image.attributes.uri.url
        },
        imgSource() {
            return process.env.VUE_APP_ENDPOINT + this.prodData.field_product_image.attributes.uri.url
        },
        brandLogo2(){
            return process.env.VUE_APP_ENDPOINT + this.prodData.field_product_logo.attributes.uri.url
        },
        lang() {
            return this.$store.state.currentLang
        }
    },
    methods:{
        accordinText(index) {
            if (this.isOpen.includes(index)) {
                return this.lang=='it' ? 'Chiudi' : 'Close'
            } else {
                return this.lang=='it' ? 'Scopri' : 'Discover more'
            }
        },
        openAccordion(index) {
            if (this.isOpen.includes(index)) {
                this.isOpen = this.isOpen.filter(i => i !== index);
            } else {
                this.isOpen.push(index);
            }
            //console.log(this.isOpen)
        },
    },
    mounted(){
        //console.log(this.prodData);
    }
}
</script>

<style lang="scss" scoped>

</style>