<template>
    <div class="template-page homepage">
        <div class="central-main">
            <div class="central carousel-header" v-if="this.pageData">
                <VueSlickCarousel ref="carousel-1" v-bind="carouselSettings" :arrows="false" :dots="true"
                    v-if="slideshowData.length > 0">
                    <div class="slide" :key="'slide'+index" v-for="(slide, index) in slideshowData">
                        <div>
                            <div class="slide-column-1">
                                <h1>{{ slide.attributes.title }}</h1>
                                <p class="subtitle">{{ slide.attributes.field_slide_catchphrase }}</p>
                                <img class="logo" v-if="slide.field_slideshow_logo" height="50" width="auto"
                                    :src="logoImg(slide)" alt="">
                                <a :href="slug(slide, index)">{{ lang=='it' ? 'Approfondisci' : 'Discover more'}}<img src="../assets/icon-arrow.svg" alt=""></a>
                            </div>
                            <div class="slide-column-2">
                                <div class="num"><span
                                        class="current">{{ index+1 }}</span><span>{{ slideshowData.length }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="slide-banner" :style="{  backgroundImage: 'url(' + slideBackground(slide) + ')' }">
                        </div>
                    </div>
                </VueSlickCarousel>
            </div>
        </div>
        <div class="home-intro" v-if="this.pageData">
            <div class="container">
                <div class="intro" v-if="this.pageData.field_page_ui_testo && this.pageData.field_page_ui_testo[0]">
                    <h2>
                        {{ this.pageData.field_page_ui_testo[0].attributes.field_ui_testo[0].summary }}
                    </h2>
                    <br>
                    <h2 v-html="this.pageData.field_page_ui_testo[0].attributes.field_ui_testo[0].processed">
                    </h2>
                </div>
                <div class="description"
                    v-if="this.pageData.field_page_ui_testo && this.pageData.field_page_ui_testo[0]">
                    <p v-html="this.pageData.field_page_ui_testo[0].attributes.field_ui_testo[1].processed"></p>

                    <!-- <div class="row-video">
                        <div v-parallax="-0.3" :class="['video-container',{videoPlaying:isPlaying}]"
                            ref="videoContainer" @click="playVideo()">
                            <div class="controls" ref="videoControls">
                                <img class="play" src="../assets/play-button.svg" width="71" height="71" alt="">
                                <img class="pause" src="../assets/pause-button.svg" width="71" height="71" alt="">
                            </div>
                            <video ref="video" @ended="onEnd()">
                                <source src="../assets/video/Illy_PolodelGusto_2021_def.mp4" type="video/mp4">
                            </video>
                        </div>
                    </div>
                     -->
                </div>
            </div>
            <div class="banner" v-if="this.pageData.field_page_ui_testo && this.pageData.field_page_ui_testo[0]">
                <h2>{{ this.pageData.field_page_ui_testo[0].attributes.field_ui_testo[2].summary }}</h2>
                <img src="../assets/home-page-logos.png" alt="">
            </div>
        </div>

        <div class="central carousel-prodotti" v-if="productData.length > 0">
            <div class="container">
                <p class="role">{{ this.lang=='it' ? 'Esempi di eccellenza' : 'Examples of Excellence'}}</p>
            </div>
            <div class="prev-arrow"><button @click="prev(2)">&#60;</button></div>
            <VueSlickCarousel ref="carousel-2" v-bind="carouselSettings" :arrows="false" :dots="false">
                <div class="slide" v-for="(p, index) in productData" :key="'product'+index">
                    <scheda-prodotto :prodData="p" :brandLogo="logoOfBrand(p)" :index="index+1"
                        :total="productData.length"></scheda-prodotto>
                </div>
            </VueSlickCarousel>
            <div class="next-arrow"><button @click="next(2)">&#62;</button></div>
        </div>
        <div class="news-container" v-if="this.$route.params.lang != 'en'">
            <div class="container">
                <news-tile v-for="(n, index) in newsData.slice(0, 3)" :key="'news'+index" :ndata="n"></news-tile>

            </div>
            <router-link class="link-to-news" to="/news">
                {{ this.lang=='it' ? 'Tutte le news' : 'All the news'}} <img src="../assets/icon-arrow.svg" alt="" />
            </router-link>
        </div>
        <div class="full-image" v-if="this.pageData.field_page_ui_testo && this.pageData.field_page_ui_testo[0]">
            <img v-parallax="0.5" src="../assets/placeholder.jpg" alt="">
            <h2 v-html="this.pageData.field_page_ui_testo[0].attributes.field_ui_testo[3].processed">
            </h2>
        </div>
    </div>
</template>

<script>
    //import BasicPage from './BasicPage.vue'
    import NewsTile from '../components/NewsTile.vue'
    import SchedaProdotto from '../components/SchedaProdotto.vue'
    import {
        fetchSinglePage,
        fetchNodes,
        getTranslation
    } from '../libs/drupalClient'
    import {
        shuffleArray,
        slugify
    } from '../libs/utils'
    import VueSlickCarousel from 'vue-slick-carousel'


    export default {
        components: {
            VueSlickCarousel,
            NewsTile,
            SchedaProdotto
        },
        name: 'home',
        data: () => {
            return {
                pageData: {
                    attributes: {}
                },
                currentPageId: "aedb748a-ac0d-46d8-be9c-f894f5cf81e4",
                newsData: [],
                productData: [],
                brandsData: [],
                slideshowData: [],
                carouselSettings: {
                    pauseOnHover: true,
                    dotsClass: "slick-dots custom-dot-class",
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    accessibility: true,
                    infinite: true,
                    edgeFriction: 0.35,
                    speed: 1000,
                    dots: false,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    pauseOnDotsHover: true,
                    touchMove: true,
                    swipe: true
                },
                isPlaying: false,
                pololink : ''
            }
        },
        methods: {
            logoImg(slide) {
                return process.env.VUE_APP_ENDPOINT + slide.field_slideshow_logo.attributes.uri.url
            },
            slug(slide, index) {
                if(index == 0) 
                    return this.pololink
                else 
                    return (this.lang!=this.defaultLang ? ('/' + this.lang) : '') + '/' + slugify(slide.attributes.title.toLowerCase())
            },
            slideBackground(s) {
                return process.env.VUE_APP_ENDPOINT + s.field_slide_image.attributes.uri.url
            },
            prev(n) {
                this.$refs['carousel-' + n].prev()
            },
            next(n) {
                this.$refs['carousel-' + n].next()
            },
            play() {
                this.$refs.video.play();
            },
            pause() {
                this.$refs.video.pause();
            },
            onEnd() {
                this.isPlaying = false
            },
            playVideo() {
                if (!this.isPlaying) {
                    this.play()
                    this.isPlaying = true
                } else {
                    this.isPlaying = false
                    this.pause()
                }
            },
            logoOfBrand(p) {
                let src = 'placeholder.jpg'
                this.brandsData.forEach(b => {
                    if (b.id == p.field_ref_brand.id)
                        src = process.env.VUE_APP_ENDPOINT + b.field_brand_logo.attributes.uri.url
                })
                return src
            }
        },
        computed: {
            lang() {
                return this.$store.state.currentLang
            },
            defaultLang() {
                return this.$store.state.defaultLang
            }
        },
        mounted() {
            //console.log("v-if=this.$route.param!=", this.$route.params.lang);
            localStorage.setItem('LS_ROUTE_KEY', this.$route.path)
            //console.log(localStorage.getItem('LS_ROUTE_KEY'))

            getTranslation('ChiSiamo', this.lang)
            .then(res => {
                this.pololink = (this.lang!=this.defaultLang ? res : '/chi-siamo')
            })

            fetchSinglePage(this.currentPageId, this.lang)
            .then(res => {
                this.pageData = res[0]
            })

            fetchNodes('slideshow_home', {
                include: ['field_slide_image', 'field_slideshow_logo']
            }, this.lang).then(res => {
                let ss = shuffleArray(res.slice(1, res.length))
                this.slideshowData = res.slice(0, 1).concat(ss)
            })

            fetchNodes('news', {
                include: ['field_news_image']
            }, this.lang)
            .then(res => {
                this.newsData = res.sort((a, b) => {
                    return a.attributes.field_news_date > b.attributes.field_news_date ? -1 : 1
                })
            })

            fetchNodes('product', {
                include: ['field_product_image', 'field_product_logo', 'field_ref_brand']
            }, this.lang)
            .then(res => {
                this.productData = shuffleArray(res)
            })

            fetchNodes('brand', {
                include: ['field_brand_logo']
            }, this.lang)
            .then(res => {
                this.brandsData = res
            })
        },
        watch: {
            lang() {
                this.$router.push({
                    path: '/' + (this.lang==this.defaultLang ? '' : this.lang)
                })

                getTranslation('ChiSiamo', this.lang)
                .then(res => {
                    //console.log(res)
                    this.pololink = (this.lang!=this.defaultLang ? res : '/chi-siamo')
                })

                fetchSinglePage(this.currentPageId, this.lang)
                .then(res => {
                    this.pageData = res[0]
                })

                fetchNodes('slideshow_home', {
                    include: ['field_slide_image', 'field_slideshow_logo']
                }, this.lang).then(res => {
                    let ss = shuffleArray(res.slice(1, res.length))
                    this.slideshowData = res.slice(0, 1).concat(ss)
                })

                fetchNodes('news', {
                    include: ['field_news_image']
                }, this.lang)
                .then(res => {
                    this.newsData = res.sort((a, b) => {
                        return a.attributes.field_news_date > b.attributes.field_news_date ? -1 : 1
                    })
                })

                fetchNodes('product', {
                    include: ['field_product_image', 'field_product_logo', 'field_ref_brand']
                }, this.lang)
                .then(res => {
                    this.productData = shuffleArray(res)
                })

                fetchNodes('brand', {
                    include: ['field_brand_logo']
                }, this.lang)
                .then(res => {
                    this.brandsData = res
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../styles/colors";
    @import '../../node_modules/vue-slick-carousel/dist/vue-slick-carousel.css';
    @import '../../node_modules/vue-slick-carousel/dist/vue-slick-carousel-theme.css';

    .role {
        position: relative;

        &:before {
            content: "";
            width: 120px;
            height: 1px;
            position: absolute;
            background-color: $mainColor;
            left: -140px;
            top: 13px;
        }
    }

    .carousel-header ::v-deep .slick-dots {
        bottom: 28px;
        width: 200px;
        right: 14rem;

        li {
            margin: 0px;
        }
    }

    .carousel-header ::v-deep .slick-dots li.slick-active button:before {
        opacity: 0.75;
        color: white;
    }

    .carousel-header ::v-deep .slick-dots li button:before {
        color: white;
    }

    .central-main {
        max-width: 100%;
        padding: 0;
        top: 3rem;
        position: relative;
    }

    .carousel-header .slide {
        background-color: rgb(202, 194, 194);

        .slide-column-1 {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        .logo {
            margin: 2rem 0;
        }
    }

    .carousel-header .slide>div.slide-banner {
        width: 100%;
        object-fit: cover;
        object-position: center center;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;

        &:before {
            content: "";
            background-color: rgba(0, 0, 0, 0.486);
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            bottom: 0;
        }
    }

    .homepage {
        .home-intro {
            margin: 0 auto;
            margin-bottom: 2rem;

            .intro {
                background-color: #f0eae6;
                padding: 6rem 6rem 4rem 6rem;
                position: relative;
                z-index: 12;
                max-width: 600px;
            }

            .container {
                display: flex;
                margin-bottom: 10rem;

                h2,
                h2 ::v-deep p,
                h2 ::v-deep p em {
                    margin: 0;
                    font-family: "Cinzel";
                    font-size: 2.5rem;
                    line-height: 1em;
                    text-align: left;
                }

                h2 ::v-deep p em {
                    font-style: italic;
                }

            }

            .description {
                display: flex;
                flex-direction: column;
                width: 60%;
                pointer-events: none;

                p {
                    margin: 5rem 3rem 0rem 3rem;
                }

                .row-video {
                    pointer-events: all;
                    position: relative;
                    width: 100%;
                    height: 451px;
                    right: 15%;
                    border-bottom: 4rem;
                    top: 20%;
                    z-index: 9000;
                }
            }
        }
    }

    .banner {
        display: flex;
        padding: 3rem 3rem;
        align-items: center;
        justify-content: space-between;
        position: relative;

        &:after {
            width: 100%;
            height: 90%;
            content: "";
            z-index: -1;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background: #f0eae6 url("../assets/filigrana-banner.png") left center;
            background-repeat: no-repeat;
            position: absolute;
        }

        h2 {
            font-size: 3rem;
            color: #333333;
            width: 30%;
        }

        img {
            width: 60%;
        }
    }

    .carousel-header {
        position: relative;

        .slide {
            height: 100vh;
            position: relative;

            &>div {
                position: absolute;
                height: 70%;
                width: 46%;
                display: flex;
                justify-content: flex-end;
                text-align: right;
                right: 8%;
                z-index: 9;
                bottom: 0;
            }
        }

        .slide-column-1 {
            padding: 0 2rem;
            border-right: 1px solid #fff;
        }

        .slide-column-2 {
            padding: 0 2rem;

            .num {
                display: flex;
                flex-direction: column;

                span {
                    color: #fff;
                    font-family: "Cinzel";
                    font-size: 1.75rem;
                    position: relative;

                    &.current {
                        font-size: 3.125rem;

                        &:after {
                            width: 65px;
                            height: 1px;
                            background-color: #fff;
                            position: absolute;
                            display: block;
                            content: "";
                            transform: rotate(-45deg);
                        }
                    }
                }

                span:not(.current) {
                    left: 30px;
                    top: 11px;
                }
            }
        }

        h1 {
            color: #fff;
            line-height: 1em;
            font-size: 6.875rem;
        }

        .subtitle {
            font-size: 2em;
            line-height: 1.2em;
            margin-bottom: 2rem;
            margin-top: 1rem;
            z-index: 2;
            font-weight: 300;
            color: #fff;
        }

        a {
            z-index: 2;
            font-weight: 400;
            color: $mainColor;
            text-decoration: none;

            img {
                margin-left: 10px;
                display: inline-block;
            }

            &:hover {
                color: #fff;
            }
        }


    }

    .carousel-header {
        .slide {
            height: 80vh;
            min-height: 700px;
            position: relative;
        }
    }

    .central {
        position: relative;
    }

    button {
        background: transparent;
        font-size: 5rem;
        color: #fff;
        outline: none;
        border: none;
    }


    @media (max-width:1600px) {
        .carousel-header ::v-deep .slick-dots {
            right: 10rem;
        }

        .homepage .home-intro {
            .description {
                position: relative;

                .row-video {
                    width: 701px;
                    height: 394px;
                    right: 120px;
                    top: 111px;
                    bottom: -60px;
                }
            }

            .intro {
                padding: 4rem 4rem 4rem 3rem;
                max-width: 500px;

                .description {
                    p {
                        margin-top: 3rem;
                    }

                }

            }
        }

        .banner {
            h2 {
                font-size: 2.5rem;
                width: 40%;
            }

            img {
                width: 60%;
            }
        }

        .carousel-header .slide>div {
            width: 60%;
            right: 5%;
        }

        .carousel-header h1 {
            font-size: 5rem;
        }
    }

    @media (max-width:1200px) {

        .role {
            padding-left: 100px;

            &:before {
                left: 0;
                width: 80px;
            }
        }

        .carousel-header .slide>div {
            width: 80%;
            right: 5%;
        }

        .homepage .home-intro {
            margin-bottom: 5rem;
        }
    }

    @media (max-width:1024px) {
        .banner {
            h2 {
                font-size: 1.875rem;
            }
        }

    }

    @media (max-width: 992px) {
        .carousel-header {
            .slide {
                min-height: auto;
            }
        }

        .carousel-header ::v-deep .slick-dots {
            bottom: 87px;
            right: 3rem;
            width: 280px;
        }

        .carousel-header .slide>div {
            width: 100%;
            right: 0;
            height: 80%;
            padding: 0 1rem;
        }

        .carousel-header h1 {
            font-size: 3rem;
        }

        .carousel-header .subtitle {
            font-size: 1.5625rem;
        }

        .homepage .home-intro {
            margin-bottom: 2rem;
        }

        .homepage .home-intro .container {
            flex-direction: column;
            margin-bottom: 6rem;

            .intro {
                max-width: none;
            }

            .description {
                width: auto;
                padding: 1rem 3rem;

                p {
                    margin: 2rem 0rem 0rem 0rem;
                }

                .row-video {
                    width: 100%;
                    height: auto;
                    right: 0;
                    top: 50px;
                    bottom: 0;

                    .video-container {
                        position: relative;
                    }
                }
            }
        }

        .banner {
            flex-direction: column;

            h2 {
                width: auto;
                margin: 2rem 0rem;
            }

            img {
                width: 70%;
            }

        }

    }


    @media (max-width:768px) {
        .carousel-header .slide .logo {
            margin: 1rem 0;
        }

        .carousel-header .logo {
            height: 30px;
        }

        .news-container.container-beige.intro .news div {
            padding: 0px 0px;
        }

        .carousel-header .slide>div {
            height: 70%;

            h1 {
                font-size: 2.375rem;
            }

            .subtitle {
                font-size: 1.25rem;
            }
        }

        .carousel-header .slide-column-2 .num {
            transform: scale(0.7);
            transform-origin: left top;
        }

        .homepage .home-intro {
            .intro {
                padding: 2rem 2rem 2rem 2rem;

                h2,
                h2 ::v-deep p,
                h2 ::v-deep p em {
                    font-size: 1.875rem;
                }

                p {
                    margin-top: 2rem;
                }
            }

            .container {
                margin-bottom: 3rem;

                .description {
                    padding: 1rem 1rem;
                }
            }
        }

        .banner {
            padding: 2rem 2rem;

            img {
                width: 100%;
            }
        }
    }

    @media (max-width:576px) {
        .banner {
            padding: 2rem 1rem;
        }

        .carousel-header .logo {
            height: 30px;
        }

        ::v-deep .slick-dots {
            right: 0rem;
        }

        .carousel-header .slide-column-1 {
            padding: 0 1rem;
        }

        .carousel-header .slide>div {
            height: 80%;
        }

        .carousel-header .slide>div {
            padding: 0 15px;
        }

        .carousel-header .slide>div h1 {
            font-size: 1.875rem;
        }

        .carousel-header .slide>div .subtitle {
            font-size: 1rem;
        }

        .carousel-header .subtitle {
            margin-bottom: 1rem;
        }

        .carousel-header .slide-column-2 {
            padding: 0 1rem;
        }

        .homepage .home-intro .intro {
            padding: 2rem 1rem 2rem 1rem;
        }

        .banner h2,
        .homepage .home-intro .intro h2 ::v-deep p em,
        .homepage .home-intro .intro h2,
        .homepage .home-intro .intro h2 ::v-deep p {
            font-size: 1.625rem;
        }

        .homepage .home-intro .container .description {
            padding: 1rem 0rem;
        }
    }
</style>