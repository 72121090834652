import Vue from 'vue'
import VueRouter from 'vue-router'
import { fetchRoutes } from '../libs/drupalClient'
import store from '../store'
import Home from '../views/Home'

let itemsToUse = [
  VueRouter
]
itemsToUse.forEach((item)=>{
  Vue.use(item)
})

export function router() {
  return fetchRoutes().then(data => {

    var fetchedRoutes = data.routes.filter(r => r.lang==data.langcode)
    data.routes.filter(r => r.lang!=data.langcode).forEach(f =>
      fetchedRoutes.push(f)
    )

    var ffroutes = []
    store.commit('SET_DEFAULT_LANG', data.langcode)
    var lang = '/:lang'

    var languages = data.languages
    //console.log(fetchedRoutes)

    var pagine = []
    var path, comp


    fetchedRoutes.forEach(f => {
      comp = () => import( '../views/' + f.name + '.vue')
      if(f.name!='Home') {
        if(!pagine.includes(f.name)) {
          if(f.lang!=undefined) {
            path = (f.lang==data.langcode) ? f.path : (lang + f.path)
            ffroutes.push({ path, component: comp, name: f.name, lang: f.lang, props: true  })
            pagine.push(f.name)
          } else {
            path = f.path
            ffroutes.push({ path, component: comp, name: f.name, lang: f.lang, alias: [(lang + f.path)]})
            pagine.push(f.name)
          }
        } else {
          var as = ffroutes.find(a => a.name == f.name)
          if(as.path != (lang + f.path)) {
            //('/' + f.lang + '/' + f.path) && f.lang!=data.langcode) {//
            as.alias = []
            as.alias.push(lang + f.path)
          } 
        }
      }

      //raccogli le lingue disponibili oltre all'italiano (o quella primaria da cambiare)
      /*if(f.lang!=undefined && f.lang!=null)
        if(!languages.includes(f.lang))
          languages.push(f.lang)*/
    })
    
    //per ognuna lingua aggiuntiva aggiunge dinamicamente la route della Home come caso speciale
    store.commit('SET_LANGUAGES', languages)    
    languages.forEach(l => {
      if(l==data.langcode) {
        ffroutes.push({
          path: '/',
          name: 'Home', 
          lang: l,
          component: Home
        })
      } else {
        ffroutes.push({
          path: '/:lang',
          name: 'Home', 
          lang: l,
          component: Home
        })
      }

    })

    //console.log(ffroutes)

    return new VueRouter({
      scrollBehavior() {
        return { x: 0, y: 0 };
      },
      mode: 'history',
      base: '',
      routes: ffroutes
    })
    
  })
}
