import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    data: {},
    lastPage: '',
    currentLang: 'it',
    defaultLang: '',
    languages: []
  },
  mutations: {
    SET_DATA: function(state, payload) {
      state.data = payload;
    },
    SET_SINGLE_DATA: function(state, payload) {
      state[payload.field] = payload.data;
    },
    SET_CURRENT_LANG: function(state, payload) {
      //console.log('setting: ' + payload)
      state.currentLang = payload
    },
    SET_LAST_PAGE: function(state, payload) {
      //console.log(payload)
      state.lastPage = payload;
    },
    SET_DEFAULT_LANG: function(state, payload) {
      state.defaultLang = payload;
    },
    SET_LANGUAGES: function(state, payload) {
      state.languages = payload;
    }
  },
  modules: {
  }
})
