ù<template>
    <div class="cookie-banner">
        <vue-cookie-accept-decline :elementId="'myPanel1'" :ref="'myPanel1'" :debug="false" :type="'floating'"
            :position="'bottom-right'" :disableDecline="true" :transitionName="'slideFromBottom'"
            :showPostponeButton="false" :forceCookies="false" @status="cookieStatus"
            @clicked-accept="cookieClickedAccept" @clicked-decline="cookieClickedDecline">

            <div slot="postponeContent">
                &times;
            </div>

            <!-- Optional -->
            <div slot="message">
                <div>
                    <h3>{{ this.title }}</h3>
                    <p v-html="this.text"></p>
                </div>
            </div>

            <!-- Optional -->
            <div slot="acceptContent">
                <span>ACCETTO</span>
            </div>

            <!--<div slot="declineContent">
                <span>Rifiuto</span>
            </div>-->
        </vue-cookie-accept-decline>
    </div>
</template>

<script>
    import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
    import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';

    export default {
        name: 'cookie-banner',
        components: {
            VueCookieAcceptDecline
        },
        data: () => {
            return {
                acceptedCookie: false,
                status: null,
                title: "Cookie policy",
                text: `<p>Questo sito non utilizza cookie di profilazione, ma solo cookie tecnici propri e di terzi.
                Per saperne di più e disabilitare tutti o alcuni cookie visita la pagina della Cookie Policy. 
                Proseguendo la navigazione senza modificare le impostazioni e cliccando su "ACCETTO" si acconsente all’uso dei cookie.</p>`,
            }
        },
        methods: {
            cookieStatus(status) {
                //console.log('status: ' + status)
                this.status = status
            },
            cookieClickedAccept() {
                console.log('cookies accepted')
                this.status = 'accept'
            },
            cookieClickedDecline() {
                console.log('cookies declined')
                this.status = 'decline'
            },
            cookieRemovedCookie() {
                console.log('removed cookies')
                this.status = null
                this.$refs.myPanel1.init()
            },
            removeCookie() {
                console.log('removed cookies')
                this.$refs.myPanel1.removeCookie()
            },
        },
        mounted() {}
    }
</script>

<style lang="scss">
</style>